@import "./layout/result-block/result-block.styles";

.screenshot-builder-page {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  .toolbar {
    width: 25%;
    border-right: 1px solid grey;
    background-color: #f8f9fa;
  }

  .toolbar, .result-block {
    padding: 1rem;
    max-height: 100vh;
  }
}