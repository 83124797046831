$text-color: #000000;
$border-color: #FFFFFF;
$border-color-on-hover: #67CCFF;

.retab {
  display: flex;
  flex-direction: row;

  .retab__tablist {
    display: flex;
    flex-direction: column;

    .retab__tablist__tab {
      padding: .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      color: $text-color;
      cursor: pointer;
      font-size: 1rem;
      text-align: left;
      border-radius: 0;
      border: 1px solid lightgrey;

      img {
        height: 20px;
      }

      &:hover, &.selected  {
        background-color: #d5d5d5;
        //border: 1px solid lightgrey;
      }
    }
  }

  .retab__tabpanel {
    padding: .5rem;

    color: $text-color;
  }
}

.tab-title {
  font-weight: bold;
}
.tab-subtitle {
  color: #67CCFF;
}

.tabpanel-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.tabpanel-subtitle {
  color: #67CCFF;
  font-size: 1rem;
}
.tabpanel-text {
  margin-top: 1rem;
}