@mixin full-size {
  height: 100%;
  width: 100%;
}

html {
  @include full-size;
}
body {
  @include full-size;

  #root {
    @include full-size;

    .App {
      @include full-size;

      .app-routes {
        @include full-size;

        main {
          @include full-size;
        }
      }
    }
  }
}