.results-block {
  width: 75%;

  &__devices {
    display: flex;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    overflow-y: auto;
    padding-top: 1rem;

    .screen {
      min-height: 500px;
      height:fit-content;
      width: 250px;
      padding: 1rem;
      max-width: 300px;
      background-color: #d5d5d5;
      cursor: pointer;

      &__heading {
        font-size: 1.1rem;
        text-align: center;
        font-weight: 1;
      }
      &__image-block {
        width: 100%;
        display: flex;
        justify-content: center;

        &__img {
          height: 200px;
          max-width: 80%;
        }
      }
    }
    .selected-screen {
      border: 2px dashed green;
    }
  }
}